import React from "react";
import "./Contact.scss";

const Contact = () => {
  return (
    <div id="contact">
      <a href="https://facebook.com/thechiliwey"><i className="fab fa-facebook" /><strong> /thechiliwey</strong></a>
      <a href="https://instagram.com/thechiliwey"><i className="fab fa-instagram" /><strong> @thechiliwey</strong></a>
      <a href="tel:+5492615891382"><i className="fas fa-phone-square" /><strong>+54 9 2615891382</strong></a>
    </div>
  );
};

export default Contact;