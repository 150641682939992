import React from "react";
import mati from "./mati.jpg";
import dami from "./dami.jpg";
import andres from "./andres.jpg";
import lucho from "./lucho.jpg";
import "./Bio.scss";
import { Col, Row } from "react-bootstrap";

const Bio = () => {
  return (
    <div>
      <p><strong>THE CHILI WEY</strong> es un tributo argentino a <strong>RED HOT CHILI PEPPERS</strong>.</p>
      <p>Formada en 2017 con la idea de recrear el espíritu de un recital de RHCP, la banda se ha presentado
        en numerosos eventos. Trabajando para acercar su sonido e imagen lo más posible y brindar una experiencia
        electrizante al público y los fans de los Red Hot, se ha invertido en instrumentos y equipamiento igual al utilizado
        por Flea, Chad, John y Anthony.
      </p>
      <Row className="mt-4">
        <Col xs={6} lg={3}>
          <p className="member">
            <img src={dami} alt="Damian Saez" />
            <span>Damián Saez</span>
          </p>
        </Col>
        <Col xs={6} lg={3}>
          <p className="member">
            <img src={mati} alt="Matias Altabe" />
            <span>Matías Altabe</span>
          </p>
        </Col>
        <Col xs={6} lg={3}>
          <p className="member">
            <img src={andres} alt="Andres Ceccoli" />
            <span>Andrés Ceccoli</span>
          </p>
        </Col>
        <Col xs={6} lg={3}>
          <p className="member">
            <img src={lucho} alt="Luciano Forconi" />
            <span>Luciano Forconi</span>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Bio;