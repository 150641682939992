import poster from "./poster.jpg";
import videobg from "./promo.mp4";
import audio from "./promo.mp3";
import chili from "./chili.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";
import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import NavMenu from "./NavMenu";
import Bio from "./Bio";
import Contact from "./Contact";
import Videos from "./Videos";
import { Col, Container, Row } from "react-bootstrap";
import expand from "./expand.svg";
import minimize from "./minimize.svg";
import speaker from "./speaker.svg";
import EqIcon from "./EqIcon";
import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBq2DXUFWjNtHk4aibNvqznekukjeuOCNI",
  authDomain: "thechiliwey-cba7b.firebaseapp.com",
  projectId: "thechiliwey-cba7b",
  storageBucket: "thechiliwey-cba7b.appspot.com",
  messagingSenderId: "713910997472",
  appId: "1:713910997472:web:c48c186fd038d259a81b85",
  measurementId: "G-BREN5MWHTM"
};

firebase.initializeApp(firebaseConfig);

const FirebaseAnalytics = () => {
  let location = useLocation();
  useEffect(() => {
    const pagePath = location.pathname + location.search;
    firebase.analytics().setCurrentScreen(pagePath);
    firebase.analytics().logEvent("page_view", {pagePath});
  }, [location]);
  return null;
};

function App() {
  const [muted, setMuted] = useState(true);
  const onMutedChange = useCallback(() => {
    setMuted(muted => !muted);
    firebase.analytics().logEvent(muted ? 'enable_audio' : 'disable_audio');
  }, [muted]);

  const [collapsed, setCollapsed] = useState(false);
  const onCollapsedChange = useCallback(() => {
    setCollapsed(collapsed => !collapsed);
    firebase.analytics().logEvent(collapsed ? 'restore_content' : 'minimize_content');
  }, [collapsed]);

  return (
    <BrowserRouter>
      <FirebaseAnalytics />
      <div className="site">
        <div id="videoBG">
          <video poster={poster} autoPlay playsInline loop controlsList="nodownload nofullscreen" disablePictureInPicture>
            <source src={videobg} type="video/mp4" />
          </video>
        </div>
        <audio src={audio} autoPlay muted={muted} loop>
          Your browser does not support music files.
        </audio>
        <div id="overlay" style={{ display: collapsed ? 'none' : 'block' }} />

        {!collapsed &&
        <main className="justify-content-start">
          <Container fluid>
            <Row>
              <Col xs={12} lg={6} className="wrapper offset-lg-3 mt-lg-0 mt-4 px-3 p-lg-0">
                <section id="content" className="height">
                  <div className="d-flex align-items-start">
                    <img src={chili} width="140" className="mb-4" alt="The Chili Wey"/>
                    <span id="controls" className="d-block flex-grow-1 text-right">
                    {muted && <EqIcon onClick={onMutedChange}/>}
                      {!muted && <img src={speaker} width="32" alt="Sound" style={{ cursor: 'pointer' }} onClick={onMutedChange} />}
                      <img src={collapsed ? expand : minimize} width="32" alt="Minimize" style={{ cursor: 'pointer' }} 
                        onClick={onCollapsedChange} />
                    </span>
                  </div>

                  <NavMenu />

                  <Switch>
                    <Route path="/videos"><Videos /></Route>
                    <Route path="/contact"><Contact /></Route>
                    <Route exact path="/"><Bio /></Route>
                  </Switch>

                  <div class="gigstarter-button" data-slug="the-chili-wey" data-version="1">
                    <a href="https://www.gigstarter.es/artists/the-chili-wey" target="_blank"> 
                      <img src="https://s3-eu-west-1.amazonaws.com/gigstarter/contratanos.png" alt="Contrata The Chili Wey" />
                    </a>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </main>
        }
        {collapsed &&
        <main className="justify-content-end align-items-end">
          <Container fluid>
            <Row>
              <Col xs={12} lg={12} className="wrapper bg-dark py-4">
                <section id="content">
                  <div className="d-flex align-items-center">
                    <img src={chili} width="140" alt="The Chili Wey" />
                    <span id="controls" className="d-block flex-grow-1 text-right">
                      <div class="gigstarter-button d-none d-sm-inline-flex mr-2" data-slug="the-chili-wey" data-version="1">
                        <a href="https://www.gigstarter.es/artists/the-chili-wey" target="_blank"> 
                          <img src="https://s3-eu-west-1.amazonaws.com/gigstarter/contratanos.png" alt="Contrata The Chili Wey" />
                        </a>
                      </div>
                      {muted && <EqIcon onClick={onMutedChange}/>}
                      {!muted && <img src={speaker} width="32" alt="Sound" style={{ cursor: 'pointer' }} onClick={onMutedChange} />}
                      <img src={collapsed ? expand : minimize} width="32" alt="Minimize" style={{ cursor: 'pointer' }} 
                        onClick={onCollapsedChange} />
                    </span>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </main>
        }
      </div>
    </BrowserRouter>
  );
}

export default App;
