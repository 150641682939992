import React from "react";

import "./EqIcon.css";

const EqIcon = ({onClick}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="#FFFFFF" style={{ cursor: 'pointer' }}
    onClick={onClick}>
    <rect className="eq-bar eq-bar--1" x="4" y="4" width="5.5" height="12"/>
    <rect className="eq-bar eq-bar--2" x="13.25" y="4" width="5.5" height="24"/>
    <rect className="eq-bar eq-bar--3" x="22.5" y="4" width="5.5" height="16"/>
  </svg>
);

export default EqIcon;