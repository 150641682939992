import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Videos.scss";

const Videos = () => {
  return (
    <Row>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/tdofjKqb4IM?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>Charlie</h4>
        </div>
      </Col>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/4uI088ITNgM?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>This Velvet Glove</h4>
        </div>
      </Col>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/E2rqRQLMvus?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>I Could Have Lied</h4>
        </div>
      </Col>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/L-1vMfsdhhs?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>Give It Away</h4>
        </div>
      </Col>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/cLA8JcewQkQ?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>Dani California</h4>
        </div>
      </Col>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/jA1De9rfn_4?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>By The Way</h4>
        </div>
      </Col>
      <Col xs={6} lg={4}>
        <div className="video-item">
          <iframe title="video" width="100%" height="160" src="https://www.youtube.com/embed/-mHEq-nFXRI?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <h4>Suck My Kiss</h4>
        </div>
      </Col>
    </Row>
  );
};

export default Videos;