import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useHistory, } from "react-router-dom";
import "./NavMenu.scss";

const NavMenu = () => {
    const history = useHistory();

    return (
        <Nav id="mainnav" onSelect={(selectedKey) => history.push(selectedKey)}>
            {/* <Nav.Item>
                <NavLink to="/"><i className="fas fa-asterisk" /></NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/bio"><i className="fas fa-clock" /></NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/contact"><i className="fas fa-phone-square" /></NavLink>
            </Nav.Item> */}

            <Nav.Item>
                <NavLink to="/" exact>Bio</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/videos">Videos</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/contact">Contacto</NavLink>
            </Nav.Item>
        </Nav>
    );
};

export default NavMenu;